<script setup lang="ts">
import CardGrid from "~/components/layout/CardGrid.vue";
import { MapPreset } from "~/types/types";
import AppLoadingWrapper from "~/components/app/AppLoadingWrapper.vue";

import electricityGridOwners from '~/assets/map-presets/electricity-grid-owners.png'
import infrastructureChargingStations from '~/assets/map-presets/infrastructure-charging-stations.png'
import infrastructureGasFuelStations from '~/assets/map-presets/infrastructure-gas-fuel-stations.png'
import infrastructureLiquidFuelStations from '~/assets/map-presets/infrastructure-liquid-fuel-stations.png'
import infrastructureOpportunities from '~/assets/map-presets/infrastructure-opportunities.png'
import infrastructureOverall from '~/assets/map-presets/infrastructure-overall.png'
import vehicleFleet from '~/assets/map-presets/vehicle-fleet.png'
import afir from '~/assets/map-presets/afir.png'


const featuredImages = {
  'electricity-grid-owners': electricityGridOwners,
  'infrastructure-charging-stations': infrastructureChargingStations,
  'infrastructure-gas-fuel-stations': infrastructureGasFuelStations,
  'infrastructure-liquid-fuel-stations': infrastructureLiquidFuelStations,
  'infrastructure-opportunities': infrastructureOpportunities,
  'infrastructure-overall': infrastructureOverall,
  'vehicle-fleet': vehicleFleet,
  afir,
}
const { find } = useStrapi();

const { data: mapPresets, pending: mapPresetsPending } = await useAsyncData(
  "map-presets",
  () => find<MapPreset>("map-presets", { populate: "*" }),
  {
    transform: (_mapPresets) =>
      _mapPresets.data
        .sort((a, b) => b.attributes.name.localeCompare(a.attributes.name))
        .sort((a, b) => (a.attributes.identifier === "coming-soon" ? 1 : -1)),
  }
);
function getBackendUrl() {
  return import.meta.env.VITE_BACKEND_URL;
}
</script>

<template>
  <AppLoadingWrapper :is-loading="mapPresetsPending" :height="1000">
    <CardGrid v-if="mapPresets" :grid="2">
      <CardWithBubble
        v-for="mapPreset in mapPresets"
        :key="mapPreset.id"
        :title="mapPreset.attributes.name"
        :description="mapPreset.attributes.description"
        :link="
          mapPreset.attributes.identifier !== 'coming-soon'
            ? `kartor-och-statistik/${mapPreset.attributes.slug}`
            : undefined
        "
        background-color="#bcc4c2"
        :background-image="featuredImages[mapPreset.attributes.identifier as keyof typeof featuredImages]"
        :alt-text="`Kartbild som visar ${mapPreset.attributes.name}`"
      >
        <div v-if="mapPreset.attributes.identifier === 'coming-soon'" class="coming-soon">
          Kommer snart
        </div>
      </CardWithBubble>
    </CardGrid>
  </AppLoadingWrapper>
</template>
<style scoped>
.coming-soon {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  background-color: #ffffffb3;
  background-blend-mode: multiply;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 16px 24px;
  font-weight: 500;
  color: #000;
}
</style>
