<script setup lang="ts">
defineProps({
  backgroundColor: {
    type: String,
    required: false,
    default: '#85D58D',
  },
  backgroundImage: {
    type: String,
    required: false,
    default: '',
  },
})
const box = ref<Element | null>(null)

function rightRoundedRect(x: number, y: number, width: number, height: number) {
  const radius = width * 0.3
  const drivingDimenstion = width < height ? width : height
  const pointerHeight = 25
  const pointerWidth = 25

  return (
    `M${x},${y}` +
    `h${drivingDimenstion}` +
    `v${drivingDimenstion - radius - pointerHeight}` +
    `a${radius},${radius} 0 0 1 ${-radius},${radius}` +
    `h${-drivingDimenstion * 0.2}` +
    `l${0},${pointerHeight}` +
    `l${-pointerWidth},${-pointerHeight}` +
    `H${0}` +
    `z`
  )
}

const bubblePath = computed(() => {
  if (!box.value) return rightRoundedRect(0, 0, 0, 0)
  const width = box.value?.clientWidth
  const height = box.value?.clientHeight
  return rightRoundedRect(0, 0, width, height)
})
const svgID = `SVG-${Math.random().toString(36).substr(2, 9)}`
</script>
<template>
  <div
    ref="box"
    class="bubbel"
    :style="`background-image: url(${backgroundImage}); --bg-color: ${backgroundColor}; clip-path: url(#${svgID});`"
  ></div>
  <svg>
    <clipPath :id="svgID">
      <path :d="bubblePath" />
      <!-- <polygon points="85.53 321.87 131.99 321.87 131.99 362.25 85.53 321.87" /> -->
    </clipPath>
  </svg>
</template>
<style lang="scss" scoped>
@import 'App.scss';
svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}
.bubbel {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  background-color: var(--bg-color);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
